import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  Delete,
  BottomLeft,
  TopRight,
  Search,
  Download,
  Edit,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;
export default {
  tableCols: [
    {
      name: t("companys.cName"),
      filed: "cName",
    },
    {
      name: t("companys.cContact"),
      filed: "cContact",
    },
    {
      name: t("companys.cContactTel"),
      filed: "cContactTel",
    },
    {
      name: t("companys.cAddress"),
      filed: "cAddress",
    },
    {
      name: t("companys.cMaxDevNum"),
      filed: "cMaxDevNum",
    },
    {
      name: t("companys.cLastUpdated"),
      filed: "cLastUpdated",
    },
  ],
  tableOps: [
    {
      opType: "btn",
      icon: markRaw(Edit),
      name: t("commKey.Edit"),
      type: "",
      click: (row, callback) => {
        api.sys_companys_row({ id: row.id }).then((res) => {
          // console.log(res.data.row, "11");
          let _row = res.data.row;
          callback({ type: "edit", row: _row });
        });
      },
    },
    {
      opType: "del",
      icon: markRaw(Delete),
      name: t("commKey.Delete"),
      type: "danger",
      click: (row, callback) => {
        callback({ type: "delete", row: row });
        // activeKey.value = "test";
      },
    },
  ],
  searchCols: [
    {
      type: "ipt",
      title: t("commKey.keynameCpy"),
      key: "keyname",
      val: "",
    },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },
    {
      type: "btn",
      btnType: "primary",
      title: t("commKey.Add"),
      icon: markRaw(Plus),
      click: (callback) => {
        callback({ type: "add", row: { id: 0 } });
      },
    },
  ],
  formCols: [
    {
      type: "ipt",
      label: t("companys.cName"),
      field: "cName",
      placeholder: t("companys.cName"),
      val: "",
      required: true,
    },
    {
      type: "ipt",
      label: t("companys.cContact"),
      field: "cContact",
      placeholder: t("companys.cContact"),
      val: "",
      required: true,
    },
    {
      type: "ipt",
      label: t("companys.cContactTel"),
      field: "cContactTel",
      placeholder: t("companys.cContactTel"),
      val: "",
      required: true,
    },
    {
      type: "ipt",
      label: t("companys.cAddress"),
      field: "cAddress",
      placeholder: t("companys.cAddress"),
      val: "",
      required: false,
    },
    {
      type: "datetime",
      label: t("companys.cRegDate"),
      field: "cRegDate",
      placeholder: t("companys.cRegDate"),
      val: moment().format("YYYY-MM-DD HH:mm:ss"),
      required: true,
    },
    {
      type: "ipt",
      label: t("companys.cMaxDevNum"),
      field: "cMaxDevNum",
      placeholder: t("companys.cMaxDevNum"),
      val: "",
      required: false,
    },
  ],
};
